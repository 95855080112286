.small-align-center {
  @include breakpoint(small only) {
    justify-content: center !important;
  }
}

.medium-align-center {
  @include breakpoint(medium only) {
    justify-content: center !important;
  }
}

.large-align-center {
  @include breakpoint(large only) {
    justify-content: center !important;
  }
}

.small-align-left {
  @include breakpoint(small only) {
    justify-content: left !important;
  }
}

.medium-align-left {
  @include breakpoint(medium only) {
    justify-content: left !important;
  }
}

.large-align-left {
  @include breakpoint(large only) {
    justify-content: left !important;
  }
}

.small-align-right {
  @include breakpoint(small only) {
    justify-content: right !important;
  }
}

.medium-align-right {
  @include breakpoint(medium only) {
    justify-content: right !important;
  }
}

.large-align-right {
  @include breakpoint(large only) {
    justify-content: right !important;
  }
}

#main {
  margin-top: 82px;
  padding: 30px 0 0;
  background: url("../img/bck-top-right-transp.svg") center top no-repeat,
              url("../img/bck-right-transp.svg") right top 140px repeat-y,
              url("../img/bck-left-transp.svg") left top 230px repeat-y,
              url("../img/bck-bottom-transp.svg") center bottom -1px no-repeat,
              url("../img/bck-left-middle-transp.svg") right calc(50% + 115px) no-repeat;
  background-size: 40%, 40%, 30%, contain, contain;
  
  @include breakpoint(medium){
    padding: 140px 0 0;
    
    background: url("../img/bck-top-left.svg") no-repeat top left, 
                url("../img/bck-top-right.svg") right 10px top no-repeat,
                url("../img/bck-right.svg") right top 140px no-repeat,
                url("../img/bck-left.svg") left top 230px no-repeat,
                url("../img/bck-bottom.svg") center bottom -1px no-repeat,
                url("../img/bck-left-middle.svg") right calc(50% + 115px) no-repeat;
    background-size: auto, auto, auto, auto, contain, auto;    
  }
  
  @include breakpoint(large){
    padding: 190px 0 0;
  }
  
  h1 {
    font-size: rem-calc(55);
    font-weight: 500;
    color: $blue;
    
    @include breakpoint(medium){
      font-size: rem-calc(70);
    }
    @include breakpoint(large){
      font-size: rem-calc(110);
    }
  }
  
  h2 {
    font-size: rem-calc(25);
    font-weight: 500;
    color: $blue;
    
    @include breakpoint(medium){
      font-size: rem-calc(30);
    }
  }
  
  h3 {
    font-size: rem-calc(20);
    font-weight: 400;
    color: $grey41;
  }
  
  .quote {
    margin-top: 50px;
    position: relative;
    color: $grey41;
    font-size: rem-calc(25);
    line-height: rem-calc(30);
    font-weight: 400;
    padding-bottom: 20px;
    
    u {
      text-decoration: none;
      border-bottom: 4px solid $orange;
      display: inline-block;
      line-height: 1;      
    }
    .author {
      display: block;
      font-size: rem-calc(16);
      color: $grey76;
      text-transform: uppercase;
      margin-top: 15px;      
    }
    .divider {
      display: inline-block;
      margin-top: 40px;
      height: 1px;
      width: 180px;
      background-color: $grey76;
    }
    &:before {
      content: "";
      background: url("../img/quote.svg");
      background-size: cover;
      position: absolute;
      top: -20px;
      left: 50%;
      width: 54px;
      height: 40px;
      transform: translateX(-50%);
      z-index: -1;
      
      @include breakpoint(medium){
        top: -40px;
        width: 108px;
        height: 80px;        
      }
    }
    
    @include breakpoint(medium){
      margin-top: 90px;
      padding-bottom: 30px;
      
      font-size: rem-calc(35);
      line-height: rem-calc(45);
    }
  }
  
  .video-incipit {
    margin-top: 20px;
    font-size: rem-calc(20);
    font-weight: 400;
    line-height: rem-calc(25);
    color: $grey41;
    a {
      color: $blue;
    }
    
    @include breakpoint(medium){
      margin-top: 40px;
      font-size: rem-calc(20);
      line-height: rem-calc(30);
    }
  }
  
  .video {
    position: relative;
    margin: 65px 0 55px;
    a {
      &:after {
        content: url("../img/play.svg");
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all .5s;
      }
      &:hover {
        &:after {
          opacity: .5;
        }
      }
      
    }
    
    img {
      display: inline-block;
      background-color: $white;      
    }
  }
  
  .come {
    margin-bottom: 65px;
    
    @include breakpoint(medium){
      margin-bottom: 125px;
    }
    
    h2 {
      margin-bottom: 30px;
    }
    h3 {
      margin-bottom: 25px;
    }
    p {
      font-size: rem-calc(13);
      line-height: rem-calc(17);
      color: $grey4D;
      
      @include breakpoint(medium){
        font-size: rem-calc(14);
        line-height: rem-calc(23);
      }
    }
    
    .date {
      display: flex;
      justify-content: center; 
      flex-direction: column;

      margin-bottom: 38px;
      
      div {
        padding: 21px 38px 21px 28px;
        display: inline-block;
        border: 1px solid $greyBA;
        color: $grey4D;
        margin-bottom: 15px;
        background-color: $white;
        
        @include breakpoint(medium){
          margin-bottom: 0;
        }
        
        span {
          color: $grey1E;
          font-size: rem-calc(24);
          border-bottom: 6px solid $orange;
          margin-left: 30px;
          display: inline-block;          
        }
        
        &.inizio {
          margin-right: -1px; 
        }
      }
      
      @include breakpoint(medium){
        flex-direction: row;
      }
    }
    
    a.button {
      display: inline-flex;
      margin-top: 42px;
      padding: 17px 20px 17px 20px;
      
      border-radius: 2px;
      
      font-size: rem-calc(18);
      font-weight: 600;
      
      align-items: center;
      
      transition: all .5s;
      
      &:after {
        content: url(../img/arrow.svg);
        display: inline-block;
        margin-left: 15px;
        line-height: 1;
      }
      
      &:hover {
        background-color: $blue;
      }
      
      @include breakpoint(medium){
        display: inline-block;
        font-size: rem-calc(20);
        padding: 22px 40px 22px 50px;
        
        &:after {
          vertical-align:auto;
        }
      }
    }
  }
  
  .video-list {
    margin-top: 45px;
    
    .cell {
      margin-bottom: 15px;
      @include breakpoint(large){
        margin-bottom: 0;
      }
      
      a {
        position: relative;
        display: inline-block;
        &:after {
          content: "";
          background: url("../img/play.svg") no-repeat center;
          background-size: cover;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 36px;
          height: 36px;
          transition: all .5s;
        }
        
        &:hover {
          &:after {
            opacity: .5;
          }
        }
      }
    }
  }
  
  .hashtag {
    margin: 47px 0;
    a {
      color: $orange;
      font-size: rem-calc(25);
      font-weight: 500;
      font-style: italic;
    }
  }
}