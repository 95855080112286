#header {
  //padding: 0 30px;
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100%;
  background-color: $white;
  
  .grid-x {
    padding: 18px 0;
    border-bottom: 2px solid rgba($greyBA, .4);
  }
  
  .logo {
    img {
      display: inline-block;
      vertical-align: sub;
    }
  }
  
  .slogan {
    display: inline-block;
    padding-left: 20px;
    padding-bottom: 18px;
    
    font-size: rem-calc(15);
    line-height: rem-calc(24);
    text-transform: uppercase;
    color: $grey1E;
    
    border-left: 2px solid $greyE1;
  }
  
  .scrollmenu {
    ul.menu {
      li {
        a {
          color: $grey1E;
          font-weight: 500;
          
          &.border-rx {
            padding: rem-calc(8 16 23);
            border-right: 2px solid $greyE1;
          }
          &.last {
            padding: rem-calc(8 16 23);
          }
          &.button {
            display: flex;
            padding: 13px 33px;
            border-radius: 2px;
            
            font-size: rem-calc(16);
            font-weight: 600;
            color: $white;
            
            transition: all .5s;
            
            @include breakpoint(medium){
              &:after {
                content: url(../img/arrow.svg);
                display: inline-block;
                margin-left: 15px;
                vertical-align: middle;
              }
            }
            
            &:hover {
              background-color: $blue;
            }
          }
          em {
            line-height: 1;
          }
        }
      }
    }
  }
}