#footer {
  padding: 33px 0 37px;
  border-top: 2px solid rgba($greyBA, .4);
  
  font-size: rem-calc(10);
  line-height: rem-calc(15);
  
  span {
    margin: 0 4px;
  }
}